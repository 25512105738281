import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const functions = getFunctions();

const useStripe = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const generatePaymentURL = async (config: {
    priceId: string,
    successURL: string,
    cancelURL: string,
  }) => {
    try {
      const { priceId, successURL, cancelURL } = config;

      setLoading(true);

      const getURL = httpsCallableFromURL(
        functions,
        firebaseCall('createStripeUrl'),
      );

      const response = (await getURL({
        priceId,
        successURL,
        cancelURL,
      })) as { data: { url: URL } };
      window.open(response?.data?.url, '_self');
    } catch (error) {
      console.error('Error generating payment URL', error);
      setLoading(false);
    }
  };

  return { generatePaymentURL, loading };
};

export default useStripe;
