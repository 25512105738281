import React from 'react';
import AmazonImage from '../../../images/amazon-logo.png';
import EbayLogo from '../../../images/ebay-logo.png';
import AliExpress from '../../../images/aliexpress.png';
import Box from '../../../components/Box';

const BrandsLogoSection = () => {
  const brandsLogos = [
    {
      size: { with: 110, height: 110 },
      src: AmazonImage,
    },
    {
      size: { with: 150, height: 50 },
      src: AliExpress,
    },
    {
      size: { with: 90, height: 90 },
      src: EbayLogo,
    },
  ];
  return (
    <Box row center>
      {brandsLogos.map((logo, index) => (
        <Box key={String(index)} center mr={30}>
          <img
            style={{ width: logo.size.with, height: logo.size.height }}
            src={logo.src}
            alt={'logo'}
          />
        </Box>
      ))}
    </Box>
  );
};

export default BrandsLogoSection;
