const env = process.env.NODE_ENV;

const isProd = env === 'production';

export const isStipeTest = true;

export const SUBSCRIPTION_PRODUCTS = {
  starter: {
    monthly: {
      id: !isStipeTest ? '' : 'price_1QDbE5LBYDqfrJYhGuWG8IXc',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 9,
      currency: '$',
      interval: 'month',
      name: 'Starter',
      description: 'Starter subscription',
      features: ['compare 300 product per month'],
    },
    yearly: {
      id: !isStipeTest ? '' : 'price_1QDruDLBYDqfrJYhK7RuRFkC',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 99,
      currency: '$',
      interval: 'year',
      name: 'Starter',
      description: 'Starter subscription',
      features: ['compare 300 product per month'],
    },
  },
  pro: {
    monthly: {
      id: !isStipeTest ? '' : 'price_1QDrv1LBYDqfrJYhl0kG60Tl',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 19,
      currency: '$',
      interval: 'month',
      name: 'Pro',
      description: 'Starter subscription',
      features: ['compare 1000 product per month'],
    },
    yearly: {
      id: !isStipeTest ? '' : 'price_1QDrveLBYDqfrJYhU5dBJRW7',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 199,
      currency: '$',
      interval: 'year',
      name: 'Pro',
      description: 'Starter subscription',
      features: ['compare 1000 product per month'],
    },
  },
};
