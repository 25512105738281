import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { useReactiveVar } from '@apollo/client';
import {
  userMarketplaceOptions,
  UserMarketplaceState,
  type UserMarketplaceStateType,
} from '../stateManagement/UserMarketplaceState';
import COLORS from '../utils/CONSTS/COLORS';
import { useTheme } from '@mui/material';

const SwitchMarketplace = () => {
  const handleChange = (event: SelectChangeEvent) => {
    UserMarketplaceState(event.target.value as UserMarketplaceStateType);
  };
  const userLanguage = useReactiveVar(UserMarketplaceState);
  const { palette } = useTheme();

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl
        variant="standard"
        sx={{ m: 1, minWidth: 80, borderColor: COLORS.SECONDARY_LIGHT }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={userLanguage}
          label="Language"
          onChange={handleChange}
          sx={{
            color: COLORS.TEXT_LIGHT,
            // backgroundColor: palette?.customBackground?.dark,
            pl: 1,
          }}
        >
          {userMarketplaceOptions.map(({ code, text, flag }) => (
            <MenuItem key={code} value={code}>
              {text} {flag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SwitchMarketplace;
