/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';

export const userMarketplaceOptions = [
  { code: 'GB', text: 'GB', flag: '🇬🇧' },
  { code: 'US', text: 'US', flag: '🇺🇸' },
];

export type UserMarketplaceStateType = 'US' | 'GB';

export const UserMarketplaceState = makeVar<UserMarketplaceStateType>('US');
