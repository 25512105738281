import { type ContentInterface } from '../utils/hooks/useLocalContent';

export const HOME_SCREEN_CONTENT: ContentInterface = {
  en: {
    hero: {
      title: 'Find Profitable Dropshipping Products with Ease',
      description:
        'Join the ultimate dropshipping community and elevate your business with our powerful comparison and product discovery tools.',
      points: [
        'Effortlessly compare prices across Amazon, eBay, and AliExpress to find the best deals.',
        'Discover profitable niches tailored to your interests with our AI-powered Niche Lab.',
        'Get instant product recommendations based on your preferences and sales data.',
        'Get custom alerts and save on product costs with our price tracking and comparison tools.',
      ],
      actionButton: 'Start Now',
      trialText: '7 days trial for $1 - Cancel any time',
    },
    header: {
      login: 'Login',
    },
  },
  es: {
    hero: {
      title: 'Encuentra productos rentables para dropshipping con facilidad',
      description:
        'Únete a la comunidad definitiva de dropshipping y eleva tu negocio con nuestras potentes herramientas de comparación y descubrimiento de productos.',
      points: [
        'Compara precios fácilmente en Amazon, eBay y AliExpress para encontrar las mejores ofertas.',
        'Descubre nichos rentables adaptados a tus intereses con nuestro Niche Lab impulsado por IA.',
        'Obtén recomendaciones instantáneas de productos basadas en tus preferencias y datos de ventas.',
        'Recibe alertas personalizadas y ahorra en costos de productos con nuestras herramientas de seguimiento y comparación de precios.',
      ],
      actionButton: 'Comienza ahora',
      trialText: '7 días de prueba por $1 - Cancela en cualquier momento',
    },
    header: {
      login: 'Iniciar sesión',
    },
  },
  pt: {
    hero: {
      title: 'Encontre produtos lucrativos para dropshipping com facilidade',
      description:
        'Junte-se à comunidade definitiva de dropshipping e eleve seu negócio com nossas poderosas ferramentas de comparação e descoberta de produtos.',
      points: [
        'Compare preços facilmente na Amazon, eBay e AliExpress para encontrar as melhores ofertas.',
        'Descubra nichos lucrativos adaptados aos seus interesses com nosso Niche Lab movido por IA.',
        'Receba recomendações instantâneas de produtos com base nas suas preferências e dados de vendas.',
        'Receba alertas personalizados e economize nos custos dos produtos com nossas ferramentas de rastreamento e comparação de preços.',
      ],
      actionButton: 'Comece agora',
      trialText: '7 dias de teste por $1 - Cancele a qualquer momento',
    },
    header: {
      login: 'Entrar',
    },
  },
  de: {
    hero: {
      title: 'Finden Sie rentable Dropshipping-Produkte ganz einfach',
      description:
        'Treten Sie der ultimativen Dropshipping-Community bei und heben Sie Ihr Geschäft mit unseren leistungsstarken Vergleichs- und Produktsuch-Tools auf die nächste Stufe.',
      points: [
        'Vergleichen Sie Preise auf Amazon, eBay und AliExpress mühelos, um die besten Angebote zu finden.',
        'Entdecken Sie profitable Nischen, die auf Ihre Interessen zugeschnitten sind, mit unserem KI-gestützten Niche Lab.',
        'Erhalten Sie sofortige Produktempfehlungen basierend auf Ihren Vorlieben und Verkaufsdaten.',
        'Erhalten Sie benutzerdefinierte Benachrichtigungen und sparen Sie Produktkosten mit unseren Preisverfolgungs- und Vergleichstools.',
      ],
      actionButton: 'Jetzt starten',
      trialText: '7 Tage Probe für $1 - Jederzeit kündigen',
    },
    header: {
      login: 'Anmelden',
    },
  },
  fr: {
    hero: {
      title: 'Trouvez facilement des produits rentables pour le dropshipping',
      description:
        'Rejoignez la communauté ultime de dropshipping et améliorez votre entreprise grâce à nos puissants outils de comparaison et de découverte de produits.',
      points: [
        'Comparez facilement les prix sur Amazon, eBay et AliExpress pour trouver les meilleures offres.',
        "Découvrez des niches rentables adaptées à vos intérêts grâce à notre Niche Lab propulsé par l'IA.",
        'Recevez des recommandations de produits instantanées basées sur vos préférences et données de ventes.',
        'Recevez des alertes personnalisées et économisez sur les coûts des produits grâce à nos outils de suivi et de comparaison des prix.',
      ],
      actionButton: 'Commencer maintenant',
      trialText: "7 jours d'essai pour $1 - Annulez à tout moment",
    },
    header: {
      login: 'Connexion',
    },
  },
};
