/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Grid2,
  Modal,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '../../components/Box';
import Loading from '../../components/Loading';
import SPACES from '../../utils/CONSTS/SPACES';
import { useUser } from '../../stateManagement/userStateManager';
import { firebaseCall } from '../../utils/CONSTS/ENDPOINTS';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import useAEAPI from '../../utils/hooks/useAEAPI';
import useAmazon from '../../utils/hooks/useAmazon';
import { LoadingButton } from '@mui/lab';
import { type AmazonProductType } from '../../utils/TYPES';
import useCompareToEbay from '../../utils/hooks/useCompareToEbay';
import LinearProgressWithLabel from '../../components/LinearProgress';
import { useNavigate } from 'react-router-dom';
import useCompareServices from '../../utils/hooks/useCompareServices';
import createAffiliateLink from '../../utils/helpers/ff';
import DarkModeSwitch from '../../components/DarkModeSwitch';
import { Palette, Search } from '@mui/icons-material';
import CopyText from '../../components/CopyText';
import COLORS from '../../utils/CONSTS/COLORS';
import FilterComponent from '../../components/FilterComponent';

const functions = getFunctions();

const DashboardLandingPage = (): JSX.Element | null => {
  const { user, permissionStatus, loading } = useUser();
  const { searchAEByImage } = useAEAPI('US');

  const setuserPerm = () => {
    const setUser = httpsCallableFromURL(
      functions,
      firebaseCall('setUserOrganisation'),
    );
    setUser({
      orgId: 'VndAl3MxSLNTpNrG9D9g',
    })
      .then((res) => {
        console.log('setUser', res);
      })
      .catch((err) => {
        console.log('setUser', err);
      });
  };

  useEffect(() => {
    if (!user) return;
    (async () => {
      try {
        // const ref = doc(db, `organisations/${user?.organisation}`);
        // const data = await getDoc(ref);
        // console.log('datadatadatadata', data.data(), user?.organisation, data);
      } catch (error) {
        console.log('errorerrorerror', error);
      }
    })();
  }, [user]);

  const navigate = useNavigate();

  const token = async () => {
    try {
      const tokens = httpsCallableFromURL(
        functions,
        firebaseCall('aETokenValidation'),
      );
      const result = await tokens({
        type: 'AE_TOKEN',
      });
      console.log('result', result);

      if ((result?.data as { message: string })?.message === 'expired')
        window.open(
          'https://api-sg.aliexpress.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://dropcompare.com/ae-autorisation&client_id=509408',
          '_blank',
        );
    } catch (error) {
      console.log('error Token', error);
    }
  };

  useEffect(() => {
    // token();
  }, []);

  if (loading) return <Loading size={100} />;

  return (
    <Box flex width="100%">
      <UserDashboard />
    </Box>
  );
};

const UserDashboard = () => {
  const [addReportModal, setAddReportModal] = React.useState(false);
  const { palette } = useTheme();

  const [searchString, setSearchString] = React.useState('');
  const componentWidth = { width: '500px' };

  const { compareProducts, comparedResults, resetList, percentage } =
    useCompareToEbay();
  const {
    bestSellers,
    searchProduct,
    loading,
    getProductDetail,
    productDetail,
  } = useAmazon(resetList);

  const { compareServices } = useCompareServices();

  const productsToDisplay =
    bestSellers?.length && !comparedResults?.length
      ? bestSellers
      : comparedResults;

  const [filters, setFilter] = useState([
    {
      name: 'price',
      selected: false,
      ascending: false,
      filter: 'product_price',
    },
    {
      name: 'hottest product',
      selected: false,
      ascending: false,
      filter: 'itemSoldLastMonth',
    },
  ]);

  console.log('bestSellers', bestSellers);

  const productsFiltered = useMemo(() => {
    let filtered = [...productsToDisplay];
    filters.forEach((filter) => {
      if (filter.selected) {
        filtered = filtered.sort((a, b) => {
          if (filter.ascending) {
            return a[filter.filter] - b[filter.filter];
          }
          return b[filter.filter] - a[filter.filter];
        });
      }
    });
    return filtered;
  }, [filters, productsToDisplay]);

  const navigate = useNavigate();
  const handleOpen = () => {
    setAddReportModal(true);
  };

  const cardWidth = 250;

  return (
    <Box flex column width="100%">
      <Box flex column width="100%">
        <Box
          mb={SPACES?.SPACE_M}
          center
          row
          flex
          width="100%"
          backgroundColor={palette?.surface?.dark}
          pt={SPACES?.SPACE_M}
          pb={SPACES?.SPACE_M}
        >
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            sx={{
              width: '60%',
              backgroundColor: palette?.surface?.main,
            }}
          />
          <LoadingButton
            onClick={() => searchProduct(searchString, 1)}
            loading={loading}
            sx={{ backgroundColor: palette?.primary?.main, ml: 1 }}
            loadingIndicator={
              <CircularProgress size={34} sx={{ color: COLORS?.WHITE }} />
            }
          >
            <Search
              sx={{
                color: loading ? 'transparent' : COLORS?.WHITE,
                fontSize: 35,
                height: 44,
              }}
            />
          </LoadingButton>
          {/* <LoadingButton
            variant="outlined"
            onClick={async () => {
              // await compareProducts(bestSellers);
              await compareServices(searchString, bestSellers);
            }}
            loading={loading}
            sx={{
              color: loading ? 'transparent' : COLORS?.WHITE,
              height: 60,
            }}
          >
            Compare
          </LoadingButton> */}
        </Box>
        {/* <FilterComponent filters={filters} setFilters={setFilter} /> */}

        <Grid2
          gap={1}
          container
          mt={2}
          sx={{ justifyContent: 'center', pr: 3, pl: 3 }}
        >
          {!!productsFiltered?.length &&
            productsFiltered.map((item: AmazonProductType, index) => (
              <Grid2
                key={item?.asin}
                size={{ xs: 12, sm: 6, md: 3, lg: 2 }}
                sx={{
                  backgroundColor: palette?.surface?.dark,
                  borderRadius: 2,
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                  paddingBottom: 2,
                  overflow: 'hidden',
                  mb: 1,
                }}

                // onClick={() => getProductDetail(item?.asin)}
              >
                <Box center flex column>
                  <Box
                    overflow="hidden"
                    center
                    flex
                    backgroundColor={'white'}
                    pr={10}
                    pl={10}
                    aspectRatio={1}
                  >
                    <a
                      href={createAffiliateLink(item?.asin)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={item.product_photo}
                        alt="product"
                        loading="lazy"
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    </a>
                  </Box>
                  <Box pl={15} pr={15} pt={15}>
                    <CopyText
                      text={item.product_title}
                      concatLength={70}
                      color={palette?.primary?.main}
                    />
                    <Typography mt={2} sx={{ opacity: 0.7 }}>
                      {item?.sales_volume}
                    </Typography>
                    <Typography mt={2} sx={{ opacity: 0.7 }}>
                      {item?.itemSoldLastMonth}
                    </Typography>
                    <Typography variant="h6">
                      {item?.product_price} {item?.currency}
                    </Typography>

                    {/* <Typography>{item?.delivery}</Typography> */}
                  </Box>
                </Box>
              </Grid2>
            ))}
        </Grid2>
      </Box>
    </Box>
  );
};

const CheapestProducts = ({
  cheapestProducts,
  isUS,
}: {
  cheapestProducts: any,
  isUS: boolean,
}) => {
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <div>
      <ul
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          listStyle: 'none',
          padding: 0,
        }}
      >
        {!!cheapestProducts?.length &&
          cheapestProducts.map((product: any, index: number) => {
            const isSelected = imageIndex === index;
            return (
              <li
                key={index}
                onClick={() => {
                  setImageIndex(index);
                }}
              >
                <img
                  src={
                    product?.thumbnail ||
                    product?.img?.[0] ||
                    product?.cover ||
                    product?.image?.imageUrl ||
                    product?.product_main_image_url
                  }
                  style={{
                    width: isSelected ? '15vw' : '7vw',
                    borderRadius: 10,
                  }}
                  alt={
                    product?.name || product?.title || product?.product_title
                  }
                />
              </li>
            );
          })}
      </ul>
      <p>
        {cheapestProducts?.[imageIndex]?.name ||
          cheapestProducts?.[imageIndex]?.title ||
          cheapestProducts?.[imageIndex]?.product_title}
      </p>
      <p>
        {!isUS
          ? cheapestProducts?.[imageIndex]?.price?.convertedFromValue
          : cheapestProducts?.[imageIndex]?.price?.value ||
            cheapestProducts?.[imageIndex]?.price ||
            cheapestProducts?.[imageIndex]?.format_price ||
            cheapestProducts?.[imageIndex]?.target_original_price}
      </p>
      <a
        href={
          cheapestProducts?.[imageIndex]?.itemWebUrl ||
          cheapestProducts?.[imageIndex]?.product_detail_url
        }
        target="_blank"
        rel="noreferrer"
      >
        PRODUCT LINK
      </a>
      <h6
        style={{
          color:
            cheapestProducts?.[imageIndex]?.priceDifference > 0
              ? 'green'
              : 'red',
        }}
      >
        {cheapestProducts?.[imageIndex]?.priceDifference}
      </h6>
      <p>
        {cheapestProducts?.[imageIndex]?.sell_points?.[0]?.selling_point_text}
      </p>
    </div>
  );
};

export default DashboardLandingPage;
