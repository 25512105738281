export function extractNumberFromAmazonItemBought(text: string): number {
  if (!text) return 0;
  const match = text.match(/(\d+\.?\d*)(K)?/i);
  if (match) {
    const [_, number, multiplier] = match;
    if (multiplier) {
      return parseFloat(number) * 1000;
    } else {
      return parseInt(number, 10);
    }
  }
  return 0; // Default case if no match is found
}
