import * as React from 'react';
import useSyncManagedUsersProfile from '../utils/hooks/useSyncManagedUsersProfile';
import { Outlet } from 'react-router-dom';
import useSyncToken from '../hooks/useSyncToken';
import { Typography } from '@mui/material';
import { isStipeTest } from '../utils/CONSTS/SUBSCRIPTION_PRODUCTS';
import Box from '../components/Box';
import COLORS from '../utils/CONSTS/COLORS';

const LoggedInAppLayout = () => {
  useSyncManagedUsersProfile();
  useSyncToken();
  return (
    <div>
      {/* <Box center backgroundColor={COLORS.ERROR}>
        <Typography textAlign={'center'} variant="h6">
          {isStipeTest ? 'THIS IS THE TEST ENVIRONMENT' : ''}
        </Typography>
      </Box> */}
      <Outlet />
    </div>
  );
};

export default LoggedInAppLayout;
