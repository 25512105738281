import * as React from 'react';
import Box from './Box';
import { Typography } from '@mui/material';
import COLORS from '../utils/CONSTS/COLORS';
import logo from '../images/logo+text2.png';

interface LogoProps {
  size?: number;
  withFont?: boolean;
}

const Logo = ({ size = 100, withFont }: LogoProps) => {
  return (
    <Box row flex>
      <img src={logo} alt="MyReportAI Logo" width={size} height={size} />
    </Box>
  );
};

export default Logo;
