import { type ContentInterface } from '../utils/hooks/useLocalContent';

export const DASHBOARD_SIDEBAR_CONTENT: ContentInterface = {
  en: {
    navigation: {
      HOME: 'Home',
      COMPARE_MARKET: 'Compare Market',
      SEARCH_MARKET: 'Search Market',
      MY_PRODUCTS: 'My Products',
      MY_FAVORITES: 'My Favorites',
      NICHE_LAB: 'Niche Lab',
      LOGOUT: 'Logout',
    },
  },
  es: {
    navigation: {
      HOME: 'Inicio',
      COMPARE_MARKET: 'Comparar Mercado',
      SEARCH_MARKET: 'Buscar Mercado',
      MY_FAVORITES: 'Mis Favoritos',
      MY_PRODUCTS: 'Mis Productos',
      NICHE_LAB: 'Laboratorio de Nichos',
      LOGOUT: 'Cerrar sesión',
    },
  },
  pt: {
    navigation: {
      HOME: 'Início',
      COMPARE_MARKET: 'Comparar Mercado',
      SEARCH_MARKET: 'Pesquisar Mercado',
      MY_PRODUCTS: 'Meus Produtos',
      MY_FAVORITES: 'Meus Favoritos',
      NICHE_LAB: 'Laboratório de Nichos',
      LOGOUT: 'Sair',
    },
  },
  de: {
    navigation: {
      HOME: 'Startseite',
      COMPARE_MARKET: 'Markt vergleichen',
      SEARCH_MARKET: 'Markt durchsuchen',
      MY_PRODUCTS: 'Meine Produkte',
      MY_FAVORITES: 'Meine Favoriten',
      NICHE_LAB: 'Nischenlabor',
      LOGOUT: 'Abmelden',
    },
  },
  fr: {
    navigation: {
      HOME: 'Accueil',
      COMPARE_MARKET: 'Comparer le Marché',
      SEARCH_MARKET: 'Rechercher dans le Marché',
      MY_PRODUCTS: 'Mes Produits',
      MY_FAVORITES: 'Mes Favoris',
      NICHE_LAB: 'Laboratoire de Niches',
      LOGOUT: 'Se déconnecter',
    },
  },
  it: {
    navigation: {
      HOME: 'Home',
      COMPARE_MARKET: 'Confronta Mercato',
      SEARCH_MARKET: 'Cerca Mercato',
      MY_PRODUCTS: 'I Miei Prodotti',
      MY_FAVORITES: 'I Miei Preferiti',
      NICHE_LAB: 'Laboratorio di Nicchie',
      LOGOUT: 'Logout',
    },
  },
  pl: {
    navigation: {
      HOME: 'Strona główna',
      COMPARE_MARKET: 'Porównaj Rynek',
      SEARCH_MARKET: 'Wyszukaj Rynek',
      MY_PRODUCTS: 'Moje Produkty',
      MY_FAVORITES: 'Moje Ulubione',
      NICHE_LAB: 'Laboratorium Niszy',
      LOGOUT: 'Wyloguj się',
    },
  },
};
