/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getFunctions,
  httpsCallableFromURL,
  type HttpsCallableResult,
} from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// interface UseGPTNicheLabProps {}

const functions = getFunctions();

const db = getFirestore();
const auth = getAuth();

interface UserMessages {
  role: 'system' | 'user' | 'assistant';
  content: [
    {
      type: 'text',
      text: string,
    },
  ];
}

type messagesType = Array<{
  role: 'system' | 'user' | 'assistant',
  content:
    | string
    | {
        response?: any,
        interests: string,
        niches: Array<{
          cons: string[],
          description: string,
          niche: string,
          pros: string[],
          product_recommendations: Array<{
            current_demand_level: string,
            description: string,
            potential_profit_margin: string,
            price_range: string,
            product_name: string,
          }>,
        }>,
      },
}> | null;
interface nicheSearchType {
  name: string;
  id: string;
  messages: messagesType;
}

const useGPTNicheLab = (nicheId: string | undefined) => {
  const [userMassages, setUserMessages] = useState<UserMessages[]>([]);
  const [loading, setLoading] = useState(false);

  const [nicheSearchData, setNicheSearch] = useState<nicheSearchType | null>(
    null,
  );

  const [aiResponse, setAiResponse] = useState<HttpsCallableResult<unknown>>();
  const getResponse = async (userInput: string) => {
    setLoading(true);
    setNicheSearch({
      name: nicheSearchData?.name ?? '',
      id: nicheSearchData?.id ?? '',
      messages: [
        ...(nicheSearchData?.messages ?? []),
        { role: 'user', content: userInput },
      ],
    });
    const search = httpsCallableFromURL(
      functions,
      firebaseCall('GPTNicheGuide'),
    );

    const response = await search({
      message: { role: 'user', content: [{ type: 'text', text: userInput }] },
      nicheSearchId: nicheId,
    });

    setAiResponse(response);

    console.log('response GPTNicheGuide', response);
    setLoading(false);
  };

  const getNicheSearch = async () => {
    const nicheSearchRef = doc(
      db,
      `users/${auth.currentUser?.uid}/nicheLabSearches/${nicheId}`,
    );
    onSnapshot(nicheSearchRef, (_doc) => {
      const data = _doc.data();

      const messages = data?.messages?.map((message: any) => {
        const role = message.role;
        return {
          role,
          content:
            role === 'user'
              ? message?.content?.[0]?.text
              : role === 'assistant'
                ? JSON.parse(message?.content as string)
                : null,
        };
      }) as messagesType;
      setNicheSearch({
        name: data?.name,
        id: data?.id,
        messages,
      });
    });
  };

  useEffect(() => {
    if (nicheId) getNicheSearch();
  }, [nicheId]);

  return {
    aiResponse,
    getResponse,
    loading,
    nicheSearchData,
  };
};

export default useGPTNicheLab;
