import {
  getFunctions,
  httpsCallableFromURL,
  type HttpsCallableResult,
} from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

// interface UseGPTNicheLabLandingProps {}

const functions = getFunctions();

const db = getFirestore();
const auth = getAuth();

interface UserMessages {
  role: 'system' | 'user';
  content: [
    {
      type: 'text',
      text: string,
    },
  ];
}

const sanitizeName = (name: string) => name.toLowerCase().replace(/\s/g, '');

const useGPTNicheLabLanding = (
  setOpenNewSearchModal?: (input: boolean) => void,
) => {
  const [userMassages, setUserMessages] = useState<UserMessages[]>([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [allSearches, setAllSearches] =
    useState<Record<string, { id: string, name: string }>>();

  const [aiResponse, setAiResponse] = useState<HttpsCallableResult<unknown>>();
  const getResponse = async (userInput: string) => {
    setLoading(true);
    setUserMessages([
      ...userMassages,
      { role: 'user', content: [{ type: 'text', text: userInput }] },
    ]);
    const search = httpsCallableFromURL(
      functions,
      firebaseCall('GPTNicheGuide'),
    );

    const response = await search({
      messages: userMassages,
    });

    setAiResponse(response);

    console.log('response GPTNicheGuide', response);
    setLoading(false);
  };

  const getNicheLabSearches = async (sync?: boolean) => {
    const userTokenRef = collection(
      db,
      `users/${auth.currentUser?.uid}/nicheLabSearches`,
    );
    const userTokenSnapShot = await getDocs(userTokenRef);
    const _allNiches = Object.fromEntries(
      userTokenSnapShot.docs.map((document) => [
        document.id,
        { id: document.id, ...document.data() },
      ]),
    ) as Record<string, { id: string, name: string }>;
    if (sync) setAllSearches(_allNiches);
    else {
      return _allNiches;
    }
  };

  useEffect(() => {
    getNicheLabSearches(true);
  }, []);

  const [name, setName] = useState<null | string>(null);
  const [createNewNicheError, setCreateNewNicheCreateError] = useState<
    // eslint-disable-next-line prettier/prettier
  string | null
  >(null);

  const createNewNicheLabSearch = async () => {
    setCreateNewNicheCreateError(null);
    if (!name) {
      setCreateNewNicheCreateError('Please enter a name');
      return;
    }
    const sanitizedName = sanitizeName(name);
    setLoading(true);

    // get all niches to see if this name exists
    const _allNiches = await getNicheLabSearches(false);
    if (_allNiches?.[sanitizedName]) {
      setCreateNewNicheCreateError('You already have a search with this name');
      setLoading(false);
      return;
    }

    // ${Object.keys(allTokens).length + 1}
    const newSearch = {
      name,
      date: serverTimestamp(),
      messages: [],
    };
    const writeRef = doc(
      db,
      `users/${auth.currentUser?.uid}/nicheLabSearches/${sanitizedName}`,
    );
    await setDoc(writeRef, newSearch);
    if (setOpenNewSearchModal) setOpenNewSearchModal(false);
    navigate(`/nichelab/${sanitizedName}`);
    setLoading(false);
  };

  return {
    aiResponse,
    getResponse,
    setName,
    name,
    loading,
    createNewNicheError,
    createNewNicheLabSearch,
    allSearches,
  };
};

export default useGPTNicheLabLanding;
