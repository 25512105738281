import React, { useEffect, useRef } from 'react';
import Box from '../../components/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import useGPTNicheLab from '../../utils/hooks/useGPTNicheLab';
import Loading from '../../components/Loading';
import { useParams } from 'react-router-dom';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { capitalize } from 'lodash';
import SPACES from '../../utils/CONSTS/SPACES';
import robotImage from '../../images/logo.png';
import robotAnimation from '../../images/logo_animation.gif';
import Grid from '@mui/material/Grid2';
import { useUser } from '../../stateManagement/userStateManager';

const NicheLabSearchDetail = () => {
  const [userInput, setUserInput] = React.useState('');
  const { palette } = useTheme();
  const { id: nicheId } = useParams();

  const { user } = useUser();

  console.log('useruseruser', user);

  const { aiResponse, getResponse, loading, nicheSearchData } =
    useGPTNicheLab(nicheId);

  const chatBoxRef = useRef(null);

  const scrollToEnd = () => {
    if (!chatBoxRef.current) return;
    const scrollHeight = (chatBoxRef.current as HTMLElement).scrollHeight;
    console.log('scrollHeight', scrollHeight);
    (chatBoxRef.current as HTMLElement).scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (!chatBoxRef.current) return;
    scrollToEnd();
  }, [nicheSearchData]);

  if (nicheSearchData === null) return <Loading />;

  return (
    <Container>
      <Typography variant="h5" fontWeight={'800'} mt={2}>
        Niche LAB
      </Typography>
      <Typography variant="body2" sx={{ opacity: 0.8 }} mb={2}>
        Find your next profitable niche
      </Typography>
      {/* <Typography variant="h5" fontWeight={'800'} mb={3} mt={3}>
        Niche LAB- {nicheSearchData.name}
      </Typography> */}

      <div
        ref={chatBoxRef}
        style={{
          height: '71vh',
          overflowY: 'scroll',
          border: `.5px solid ${palette.secondary.dark}`,
          padding: '10px',
          borderRadius: '10px',
          backgroundColor: palette.customBackground.dark,
          marginBottom: SPACES?.SPACE_M,
        }}
      >
        <Box
          ml={20}
          backgroundColor={palette.customBackground.main}
          pAll={SPACES?.SPACE_M}
          mt={SPACES?.SPACE_M}
          borderRadius={10}
          // maxWidth={'85%'}
        >
          <Box row flex vCenter mb={SPACES?.SPACE_S}>
            <img
              src={robotImage}
              alt="robot"
              style={{ width: 60, height: 60 }}
            />
            <Typography variant="body1" ml={2}>
              To help you identify a profitable dropshipping niche that aligns
              with your interests, could you please share some of your interests
              or passions? For example, do you enjoy tech gadgets, fitness,
              fashion, pets, or perhaps something else?
            </Typography>
          </Box>
        </Box>
        {nicheSearchData?.messages?.map((message, index) => (
          <Box key={String(index)}>
            <Box>
              {message?.role === 'user' &&
                typeof message?.content === 'string' && (
                  <Box
                    borderRadius={10}
                    justifyContent={'flex-start'}
                    flex
                    mt={SPACES?.SPACE_XL}
                    row
                    vCenter
                    height={50}
                    ml={20}
                  >
                    <Box
                      flex
                      center
                      width={50}
                      height={50}
                      borderRadius={50}
                      backgroundColor={palette.primary.main}
                    >
                      <Typography
                        color={palette.common.white}
                        variant="h6"
                        fontWeight={'600'}
                      >
                        {user?.displayName?.charAt(0).toUpperCase()}
                      </Typography>
                    </Box>
                    <Box borderRadius={2} pl={20} pr={20} mr={20} center flex>
                      <Typography variant="body1">
                        {message?.content}
                      </Typography>
                    </Box>
                  </Box>
                )}

              {message?.role === 'assistant' &&
                typeof message?.content === 'object' &&
                typeof message?.content?.response === 'string' && (
                  <Box mt={SPACES?.SPACE_XL}>
                    <Box
                      ml={20}
                      backgroundColor={palette.customBackground.main}
                      pAll={SPACES?.SPACE_M}
                      // maxWidth={'85%'}
                      borderRadius={10}
                    >
                      <Box row flex vCenter mb={SPACES?.SPACE_S}>
                        <img
                          src={robotImage}
                          alt="robot"
                          style={{ width: 60, height: 60 }}
                        />
                        <Typography variant="body1" ml={2}>
                          {message?.content?.response}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}

              {message?.role === 'assistant' &&
                typeof message?.content === 'object' &&
                (message?.content?.interests ||
                  !!message?.content?.niches?.length) && (
                  <Box mt={SPACES?.SPACE_XL}>
                    <Box
                      ml={20}
                      backgroundColor={palette.customBackground.main}
                      pAll={SPACES?.SPACE_M}
                      // maxWidth={'85%'}
                      borderRadius={10}
                    >
                      <Box row flex vCenter mb={SPACES?.SPACE_S}>
                        <img
                          src={robotImage}
                          alt="robot"
                          style={{ width: 60, height: 60 }}
                        />
                        <Typography variant="body1" fontWeight={'900'} ml={2}>
                          {capitalize(message?.content?.interests)}
                        </Typography>
                      </Box>
                      <Typography variant="body1">
                        {message?.content?.niches?.length &&
                          message?.content?.niches?.map((niche, nicheIndex) => (
                            <Accordion key={niche.niche}>
                              <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id={`niche-${nicheIndex}`}
                              >
                                <Box>
                                  <Typography
                                    variant="h5"
                                    color={palette.primary.main}
                                  >
                                    {nicheIndex + 1}- {niche?.niche}
                                  </Typography>
                                  <Typography variant="body1">
                                    {niche?.description}
                                  </Typography>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<GridExpandMoreIcon />}
                                    aria-controls="Cons-content"
                                    id={`Cons`}
                                  >
                                    <Typography variant="h6">Cons</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {niche?.cons?.length &&
                                      niche?.cons?.map((con) => (
                                        <Box key={con}>
                                          <Typography variant="body1">
                                            - {con}
                                          </Typography>
                                        </Box>
                                      ))}
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<GridExpandMoreIcon />}
                                    aria-controls="Cons-content"
                                    id={`Cons`}
                                  >
                                    <Typography variant="h6">Pros</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {niche?.cons?.length &&
                                      niche?.pros?.map((pro) => (
                                        <Box key={pro}>
                                          <Typography variant="body1">
                                            - {pro}
                                          </Typography>
                                        </Box>
                                      ))}
                                  </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<GridExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id={`niche-${nicheIndex}`}
                                  >
                                    <Typography variant="h6">
                                      Example Profitable Products
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {niche?.product_recommendations?.length &&
                                      niche?.product_recommendations?.map(
                                        (product) => (
                                          <Accordion key={product.product_name}>
                                            <AccordionSummary
                                              expandIcon={
                                                <GridExpandMoreIcon />
                                              }
                                              aria-controls="panel1-content"
                                              id={`niche-${nicheIndex}`}
                                            >
                                              <Typography
                                                variant="body1"
                                                fontWeight={'800'}
                                              >
                                                {product.product_name}
                                              </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              <Typography
                                                color={'text.secondary'}
                                                variant="body1"
                                              >
                                                description:{' '}
                                                {product.description}
                                              </Typography>
                                              <Typography
                                                variant="body1"
                                                color={'text.secondary'}
                                              >
                                                current demand level:{' '}
                                                {product.current_demand_level}
                                              </Typography>
                                              <Typography
                                                variant="body1"
                                                color={'text.secondary'}
                                              >
                                                potential profit margin:{' '}
                                                {
                                                  product.potential_profit_margin
                                                }
                                              </Typography>
                                              <Typography
                                                variant="body1"
                                                color={'text.secondary'}
                                              >
                                                price range:{' '}
                                                {product.price_range}
                                              </Typography>
                                            </AccordionDetails>
                                          </Accordion>
                                        ),
                                      )}
                                  </AccordionDetails>
                                </Accordion>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </Typography>
                    </Box>
                  </Box>
                )}
            </Box>
          </Box>
        ))}
        {loading && (
          <Box mt={SPACES?.SPACE_XL}>
            <Box
              ml={20}
              // backgroundColor={palette.customBackground.main}
              pAll={SPACES?.SPACE_M}
              // maxWidth={'85%'}
              borderRadius={10}
            >
              <Box row flex vCenter mb={SPACES?.SPACE_S}>
                <img
                  src={robotAnimation}
                  alt="robot"
                  style={{
                    width: 150,
                    height: 150,
                    transform: `scale(1)`,
                    animation: 'pulse 4s infinite',
                    animationTimingFunction: 'cubic-bezier(4,-14, 4, 25)',
                  }}
                />
                <Typography
                  color={palette?.grey?.[500]}
                  className="animate-charcter"
                  ml={2}
                >
                  Analyzing the market for potential opportunities ...
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </div>

      <Grid container>
        <Grid size={{ xs: 9, sm: 10 }}>
          <TextField
            id="outlined-basic"
            label="Message Drop Compare bot"
            variant="outlined"
            onChange={(e) => {
              setUserInput(e.target.value);
            }}
            value={userInput}
            sx={{
              width: '100%',
              backgroundColor: palette.surface.main,
            }}
          />
        </Grid>
        <Grid size={{ xs: 3, sm: 2 }}>
          <Button
            endIcon={<Send />}
            sx={{ height: '100%', width: '100%', ml: 1, flex: 1 }}
            variant="contained"
            disabled={userInput === '' || userInput?.length < 3}
            onClick={() => {
              if (userInput === '') return;
              getResponse(userInput);
              setUserInput('');
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NicheLabSearchDetail;
