// interface useGetComparedRecordsProps {}

import { hasPointerEvents } from '@testing-library/user-event/dist/utils';
import { getAuth } from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  where,
} from 'firebase/firestore';
import { has } from 'lodash';
import { useEffect, useState } from 'react';

const auth = getAuth();
const db = getFirestore();

const useGetComparedRecords = ({
  filterType,
}: {
  filterType?: 'favorites',
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [comparedRecords, setComparedRecords] = useState<any[]>([]);
  const [paginationIds, setPaginationIds] = useState({
    0: '',
  });
  const pageDataLimit = 10;
  const getRecords = async (type?: 'next' | 'previous') => {
    const comparedRecordsRef = collection(
      db,
      `users/${auth?.currentUser?.uid}/compareProductImages`,
    );

    let nextPageQuery;

    if (filterType === 'favorites') {
      nextPageQuery = query(
        comparedRecordsRef,
        where('isFavorite', '==', true),
        orderBy('date', 'desc'),
        limit(pageDataLimit),
      );
    } else {
      nextPageQuery = query(
        comparedRecordsRef,
        orderBy('date', 'desc'),
        limit(pageDataLimit),
      );
    }

    onSnapshot(nextPageQuery, (querySnapshot) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rcd: any[] = [];
      console.log('querySnapshot', querySnapshot.docs.length);

      querySnapshot.forEach((document) => {
        console.log(document.id, ' => ', document.data());
        rcd.push({ id: document.id, ...document.data() });
      });
      setComparedRecords(rcd);
      console.log(
        'querySnapshot.docs.length ',
        rcd?.[querySnapshot?.docs?.length - 1]?.date as string,
      );
    });
  };

  useEffect(() => {
    getRecords();
  }, []);

  const makeFavorite = (id: string, isFav: boolean) => {
    const docRef = doc(
      db,
      `users/${auth?.currentUser?.uid}/compareProductImages`,
      id,
    );
    setDoc(
      docRef,
      {
        isFavorite: !isFav,
      },
      { merge: true },
    );
  };

  const deleteRecordWithId = async (id: string) => {
    console.log('deleteRecordWithId');
    const docRef = doc(
      db,
      `users/${auth?.currentUser?.uid}/compareProductImages`,
      id,
    );
    await deleteDoc(docRef);
  };

  return {
    comparedRecords,
    makeFavorite,
    deleteRecordWithId,
    hasNextPage: false,
    getNextPage: getRecords,
  };
};

export default useGetComparedRecords;
