import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import Box from '../../components/Box';
import SPACES from '../../utils/CONSTS/SPACES';
import { SUBSCRIPTION_PRODUCTS } from '../../utils/CONSTS/SUBSCRIPTION_PRODUCTS';
import useStripe from '../../utils/hooks/useStripe';
import { Check, Palette } from '@mui/icons-material';

const Subscription = () => {
  const [checked, setChecked] = React.useState(true);

  const isMonthly = !checked;

  const durationKey = isMonthly ? 'monthly' : 'yearly';

  const { generatePaymentURL, loading } = useStripe();

  const { palette } = useTheme();

  const plans = ['Monthly', 'Yearly - 20% off'];

  return (
    <Box
      style={{
        minHeight: '100vh',
        // backgroundColor: palette.background.default,
      }}
    >
      {loading && (
        <Box
          absolute
          width={window.innerWidth}
          height={window.innerHeight}
          center
          flex
          zIndex={100}
          backgroundColor={palette.background.default}
          opacity={0.5}
        >
          <CircularProgress />
        </Box>
      )}
      <Container fixed>
        <Box pt={SPACES.SPACE_L} center flex column>
          <Typography variant="h4" fontWeight={'500'}>
            Choose a plan
          </Typography>
          <Box
            center
            spaceBetween
            width={600}
            selfCenter
            mt={SPACES?.SPACE_XXS}
          >
            <Box row center mb={SPACES?.SPACE_L}>
              <Box
                row
                backgroundColor={palette.common.black}
                borderRadius={30}
                center
                pAll={3}
              >
                {plans.map((plan, index) => {
                  return (
                    <Box
                      key={plan}
                      center
                      mr={index === 0 && 20}
                      onClick={() => {
                        setChecked(index === 1);
                      }}
                      pAll={12}
                      pl={checked === (index === 0) ? 20 : 30}
                      pr={checked === (index === 0) ? 20 : 30}
                      borderRadius={30}
                      backgroundColor={
                        checked === (index === 0)
                          ? palette.common.black
                          : palette.common.white
                      }
                    >
                      <Typography
                        color={
                          checked === (index === 0)
                            ? palette.grey[600]
                            : palette.common.black
                        }
                        variant="body1"
                      >
                        {plan}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box row center>
              {Object.entries(SUBSCRIPTION_PRODUCTS).map(([key, product]) => {
                return (
                  <Box
                    key={key}
                    center
                    mr={SPACES.SPACE_M}
                    onClick={() => {
                      generatePaymentURL({
                        priceId: product?.[durationKey]?.id,
                        successURL: product?.[durationKey]?.successUrl,
                        cancelURL: product?.[durationKey]?.successUrl,
                      });
                    }}
                  >
                    <Card
                      sx={{
                        minWidth: 275,
                        justifyTracks: 'center',
                        alignItems: 'center',
                        borderRadius: 3,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      <CardContent
                        sx={{
                          justifyTracks: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography gutterBottom sx={{ fontSize: 20 }}>
                          {product?.[durationKey]?.name}
                        </Typography>

                        <Box row alignItems="flex-end">
                          <Typography
                            variant="h3"
                            fontWeight={'800'}
                            color={palette.text.secondary}
                          >
                            {product?.[durationKey]?.currency}
                            {product?.[durationKey]?.price}
                          </Typography>

                          <Box>
                            <Typography fontSize={19}>
                              /{product?.[durationKey]?.interval}
                            </Typography>
                          </Box>
                        </Box>
                        <Box center mt={SPACES?.SPACE_L}>
                          <Typography variant="body1" fontWeight={'600'}>
                            Features:
                          </Typography>

                          {product?.[durationKey]?.features?.map((feature) => (
                            <Box key={feature} row mt={10}>
                              <Check
                                sx={{
                                  fontSize: 20,
                                  color: palette.grey[500],
                                  mr: 1,
                                }}
                              />
                              <Typography
                                color={palette.text.secondary}
                                variant="body2"
                              >
                                {feature}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </CardContent>
                      {/* <CardActions>
                        <Button size="small">Learn More</Button>
                      </CardActions> */}
                    </Card>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Subscription;
