import { useReactiveVar } from '@apollo/client';
import { UserLanguageState } from '../../stateManagement/UserLanguageState';
import { useMemo } from 'react';

type ContentSection = Record<string, string | string[]>;

export type LanguageContent = Record<string, ContentSection>;

export type ContentInterface = Record<string, LanguageContent>;
const useLocalContent = (content: ContentInterface) => {
  const localContentValue = useReactiveVar(UserLanguageState);

  const localContent = useMemo(() => {
    return content[localContentValue];
  }, [localContentValue]);

  return localContent;
};

export default useLocalContent;
