/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar, useReactiveVar } from '@apollo/client';
import { getDoc, doc, getFirestore } from 'firebase/firestore';
import { useUser } from './userStateManager';
import { useEffect } from 'react';

export const DarkModeStateManager = makeVar<boolean>(false);

export const useDarkModeState = (): [boolean, () => void] => {
  const darkModeState = useReactiveVar<boolean>(DarkModeStateManager);

  const { user, updateUser } = useUser();

  useEffect(() => {
    if (!user) return;
    DarkModeStateManager(!!user?.darkMode || false);
  }, [user]);
  const updateDarkModeState = () => {
    DarkModeStateManager(!darkModeState);
    updateUser({ darkMode: !darkModeState });
  };
  return [darkModeState, updateDarkModeState];
};
