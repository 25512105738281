/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import Box from '../Box';
import SPACES from '../../utils/CONSTS/SPACES';
import { useFirebaseUpload } from '../../utils/hooks/useFirebaseUpload';
import { LoadingButton } from '@mui/lab';

interface StartNewNicheModalProps {
  isOpen: boolean;
  onClose: () => void;
  onStart: () => Promise<void>;
  name: string | null;
  setName: (name: string) => void;
  createNewNicheError: string | null;
  loading: boolean;
}

const StartNewNicheModal = ({
  isOpen,
  onClose,
  onStart,
  name,
  setName,
  createNewNicheError,
  loading,
}: StartNewNicheModalProps) => {
  const theme = useTheme();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="modal"
        backgroundColor={theme.palette.background.paper}
        width="720px"
        pAll={SPACES?.SPACE_XXL}
        relative
        borderRadius={SPACES?.SPACE_S}
      >
        <Box column height={'100%'} width={'100%'} flex center>
          <Typography variant="h5" color="primary" fontWeight="bold">
            Start New Niche Search
          </Typography>
          {createNewNicheError && (
            <Typography variant="body1" color="error">
              {createNewNicheError}
            </Typography>
          )}
          <Typography variant="body1" sx={{ mt: 2 }}>
            please enter a unique name for this search
          </Typography>
          <TextField
            sx={{ mt: 1, width: '70%' }}
            id="outlined-basic"
            label="name eg. 'tech gadgets'"
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          />
          <LoadingButton
            variant="contained"
            onClick={onStart}
            loading={loading}
            sx={{ mt: 2 }}
          >
            Start
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default StartNewNicheModal;
