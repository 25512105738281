import React from 'react';
import Box from '../../components/Box';
import { Button, TextField, Typography } from '@mui/material';
import nichelabimage from '../../images/nichelabimage.png';
import { Add, PostAdd, Send } from '@mui/icons-material';
import StartNewNicheModal from '../../components/modals/StartNewNicheModal';
import { values } from 'lodash';
import { useNavigate } from 'react-router-dom';
import useGPTNicheLabLanding from '../../utils/hooks/useGPTNicheLabLanding';

const NicheLabLanding = () => {
  const [userInput, setUserInput] = React.useState('');
  const [openNewSearchModal, setOpenNewSearchModal] = React.useState(false);
  const {
    getResponse,
    loading,
    name,
    setName,
    createNewNicheError,
    createNewNicheLabSearch,
    allSearches,
  } = useGPTNicheLabLanding(setOpenNewSearchModal);
  const navigate = useNavigate();

  return (
    <Box center flex column>
      <img
        src={nichelabimage}
        alt="Niche Lab"
        style={{
          width: '20vw',
          height: '20vh',
          objectFit: 'contain',
        }}
      />
      <Typography variant="h5" fontWeight={'800'}>
        Niche LAB
      </Typography>
      <Box maxWidth={'60vw'}>
        <Typography variant="body1" textAlign={'center'}>
          Uncover your perfect dropshipping e-commerce! Discover trending
          markets that align with your passions and set yourself up for success.
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          setOpenNewSearchModal(true);
        }}
        startIcon={<Add />}
      >
        Start A new Niche search
      </Button>
      <Box>
        {values(allSearches).map((search) => {
          return (
            <Box
              key={search?.id}
              onClick={() => {
                navigate(`/nichelab/${search?.id}`);
              }}
            >
              <Typography>{search.name}</Typography>
            </Box>
          );
        })}
      </Box>
      {openNewSearchModal && (
        <StartNewNicheModal
          isOpen={openNewSearchModal}
          onClose={() => {
            setOpenNewSearchModal(false);
          }}
          onStart={async () => {
            await createNewNicheLabSearch();
          }}
          name={name}
          setName={setName}
          createNewNicheError={createNewNicheError}
          loading={loading}
        />
      )}
    </Box>
  );
};

export default NicheLabLanding;
