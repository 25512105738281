import { User, UserInfo, getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

const useIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<undefined | boolean>(undefined);

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      setIsLoggedIn(!!user);
    });
  }, []);

  return isLoggedIn;
};

export default useIsLoggedIn;
