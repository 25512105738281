import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { useReactiveVar } from '@apollo/client';
import {
  userLanguageOptions,
  UserLanguageState,
  type UserLanguageStateType,
} from '../stateManagement/UserLanguageState';
import COLORS from '../utils/CONSTS/COLORS';

const SwitchLanguage = () => {
  const handleChange = (event: SelectChangeEvent) => {
    UserLanguageState(event.target.value as UserLanguageStateType);
  };
  const userLanguage = useReactiveVar(UserLanguageState);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl
        variant="standard"
        sx={{ m: 1, minWidth: 80, borderColor: COLORS.SECONDARY_LIGHT }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={userLanguage}
          label="Language"
          onChange={handleChange}
          sx={{ color: COLORS.TEXT_LIGHT }}
        >
          {userLanguageOptions.map(({ code, text }) => (
            <MenuItem key={code} value={code}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SwitchLanguage;
