import { type ContentInterface } from '../utils/hooks/useLocalContent';

export const APP_LANDING_SCREEN: ContentInterface = {
  en: {
    topSection: {
      title: 'The best way to find your next product',
      subtitle:
        'Our AI-powered product research tool helps you find winning products in few clicks.',
    },
  },
  es: {
    topSection: {
      title: 'La mejor forma de encontrar tu próximo producto',
      subtitle:
        'Nuestra herramienta de investigación de productos con IA te ayuda a encontrar productos ganadores en pocos clics.',
    },
  },
  pt: {
    topSection: {
      title: 'A melhor maneira de encontrar seu próximo produto',
      subtitle:
        'Nossa ferramenta de pesquisa de produtos com IA ajuda você a encontrar produtos vencedores em poucos cliques.',
    },
  },
  de: {
    topSection: {
      title: 'Finden Sie Ihr nächstes Produkt auf die beste Weise',
      subtitle:
        'Unser KI-gestütztes Produktforschungstool hilft Ihnen, in wenigen Klicks Gewinnprodukte zu finden.',
    },
  },
  fr: {
    topSection: {
      title: 'La meilleure façon de trouver votre prochain produit',
      subtitle:
        "Notre outil de recherche de produits alimenté par l'IA vous aide à trouver des produits gagnants en quelques clics.",
    },
  },
  it: {
    topSection: {
      title: 'Il modo migliore per trovare il tuo prossimo prodotto',
      subtitle:
        'Il nostro strumento di ricerca di prodotti alimentato da IA ti aiuta a trovare prodotti vincenti in pochi clic.',
    },
  },
  pl: {
    topSection: {
      title: 'Najlepszy sposób na znalezienie następnego produktu',
      subtitle:
        'Narzędzie badawcze do produktów zasilane sztuczną inteligencją pomaga znaleźć wygrywające produkty w kilka kliknięć.',
    },
  },
};
