/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { type User, getAuth } from 'firebase/auth';
import {
  type DocumentData,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';

import useIsLoggedIn from './useIsLoggedin';
import { UserStateManagerState } from '../../stateManagement/userStateManager';
import { SUBSCRIPTION_PRODUCTS } from '../CONSTS/SUBSCRIPTION_PRODUCTS';
import { flatten, keys, values } from 'lodash';
import { useNavigate } from 'react-router-dom';

const useSyncUser = () => {
  const auth = getAuth();
  const database = getFirestore();
  const isLoggedIn = useIsLoggedIn();

  const navigation = useNavigate();

  const getUserAndPermission = async (): Promise<void> => {
    UserStateManagerState({ ...UserStateManagerState(), loading: true });
    if (auth?.currentUser?.uid) {
      const userRef = doc(database, 'users', auth.currentUser?.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData: DocumentData = userSnap.data();
        const merge = { ...auth?.currentUser, ...userData };
        const perm = await auth.currentUser?.getIdTokenResult(true);
        if (!perm?.claims?.hasAccess) navigation('/');
        const subscribedProduct = flatten(
          values(SUBSCRIPTION_PRODUCTS).map((item) => values(item)),
        ).find((product) => product?.id === perm?.claims?.priceId);

        UserStateManagerState({
          ...UserStateManagerState(),
          permissionStatus: {
            hasAccess: !!perm?.claims?.hasAccess,
            productId: perm?.claims?.productId as string,
            priceId: perm?.claims?.priceId as string,
            subscribedProduct,
          },
          user: merge,
          loading: false,
        });
      }
    }
  };

  const updateUser = async (data: any) => {
    if (!auth.currentUser?.uid) return;
    try {
      const userRef = doc(database, 'users', auth.currentUser?.uid);
      await setDoc(userRef, data, { merge: true });
    } catch (error) {
      console.error('Error signing in with email and password', error);
    }
  };

  React.useEffect(() => {
    getUserAndPermission();
    UserStateManagerState({
      ...UserStateManagerState(),
      updateUser,
    });
  }, [isLoggedIn]);
};

export default useSyncUser;
