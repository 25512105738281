import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSyncManagedUsersProfile from '../../utils/hooks/useSyncManagedUsersProfile';
import { useUser } from '../../stateManagement/userStateManager';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../../utils/CONSTS/ENDPOINTS';

const functions = getFunctions();
const AEAuthCode = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get('code');
  const { user, updateUser } = useUser();
  console.log('searchParams', code);
  const requestToken = async () => {
    try {
      console.log('requestToken called', code);

      const tokens = httpsCallableFromURL(
        functions,
        firebaseCall('requestAEToken'),
      );
      await tokens({
        code,
        refreshToken: null,
      });
      navigate('/');
    } catch (error) {
      console.log('error Token', error);
    }
  };

  useEffect(() => {
    if (code) {
      updateUser({ aEAuthCode: code });
      requestToken();
    }
  }, []);

  return (
    <div>
      <p>aEAuthCode 2</p>
    </div>
  );
};

export default AEAuthCode;
