/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';

export const userLanguageOptions = [
  { code: 'en', text: 'English' },
  { code: 'es', text: 'Español' },
  { code: 'pt', text: 'Português' },
  { code: 'fr', text: 'Français' },
  { code: 'de', text: 'Deutsch' },
  // { code: 'it', text: 'Italiano' },
  // { code: 'ja', text: '日本語' },
  // { code: 'ko', text: '한국어' },
  // { code: 'ru', text: 'русский' },
  // { code: 'zh', text: '中文' },
  // { code: 'zh-TW', text: '繁體中文' },
  // 'fr',
  // 'de',
  // 'it',
  // 'ja',
  // 'ko',
  // 'pt',
  // 'ru',
  // 'zh',
  // 'zh-TW',
];

export type UserLanguageStateType = 'en' | 'es' | 'pt';
// | 'fr'
// | 'de'
// | 'it'
// | 'ja'
// | 'ko'
// | 'pt'
// | 'ru'
// | 'zh'
// | 'zh-TW';

export const UserLanguageState = makeVar<UserLanguageStateType>('en');
