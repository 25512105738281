import { parseInt } from 'lodash';

const extractPrice = (priceString: string) => {
  const regex = /\d+\.\d+/g; // Matches sequences of digits followed by a dot and more digits
  if (!priceString) return [];
  const matches = priceString.match(regex);
  return matches ? matches.map(Number) : []; // Convert string matches to numbers
};

export default extractPrice;
