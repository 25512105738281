/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { UserMarketplaceState } from '../../stateManagement/UserMarketplaceState';
import { log } from 'console';
import { AmazonSearchState } from '../../stateManagement/AmazonSearchState';
import { extractNumberFromAmazonItemBought } from '../helpers/extractNumberFromAmazonItemBought';
import { type AmazonProductType } from '../TYPES';
import extractPrice from '../helpers/extractPrice';

const useAmazon = (resetList: () => void) => {
  const [productDetail, setProductDetail] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const region = useReactiveVar(UserMarketplaceState);

  const bestSellers = useReactiveVar(AmazonSearchState);

  console.log('regionregionregionregion', region);

  const getBestSellers = async (category: string, page: number) => {
    setLoading(true);
    resetList();
    try {
      const data = null;

      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === this.DONE) {
          console.log('HEEREEE', JSON.parse(this?.response));
          AmazonSearchState(JSON.parse(this.response)?.data?.best_sellers);
        }
      });

      xhr.open(
        'GET',
        `https://real-time-amazon-data.p.rapidapi.com/best-sellers?category=${category?.toLowerCase()}&type=BEST_SELLERS&page=${page}&country=${region}`,
      );
      xhr.setRequestHeader(
        'x-rapidapi-key',
        '5e03767350msh99c49dea5537532p18783ejsnfe6cda87f482',
      );
      xhr.setRequestHeader(
        'x-rapidapi-host',
        'real-time-amazon-data.p.rapidapi.com',
      );

      xhr.send(data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const searchProduct = async (query: string, page: number) => {
    resetList();
    setLoading(true);
    AmazonSearchState([]);
    try {
      const data = null;

      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === this.DONE) {
          console.log(
            'amazon search',
            JSON.parse(this?.response)?.data?.products,
          );
          AmazonSearchState(
            JSON.parse(this.responseText)?.data?.products?.map(
              (item: AmazonProductType) => {
                console.log(
                  'itemitemitem',
                  extractNumberFromAmazonItemBought(item?.sales_volume),
                );

                return {
                  ...item,
                  product_price: extractPrice(item?.product_price)?.[0],
                  itemSoldLastMonth: extractNumberFromAmazonItemBought(
                    item?.sales_volume,
                  ),
                };
              },
            ),
          );
          setLoading(false);
        }
      });

      xhr.open(
        'GET',
        `https://real-time-amazon-data.p.rapidapi.com/search?query=${query}&page=${page}&country=${region}&sort_by=RELEVANCE&product_condition=NEW&is_prime=false`,
      );
      xhr.setRequestHeader(
        'x-rapidapi-key',
        '5e03767350msh99c49dea5537532p18783ejsnfe6cda87f482',
      );
      xhr.setRequestHeader(
        'x-rapidapi-host',
        'real-time-amazon-data.p.rapidapi.com',
      );

      xhr.send(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getProductDetail = async (asin: string) => {
    setLoading(true);
    try {
      const data = null;

      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === this.DONE) {
          console.log('product detail search', JSON.parse(this?.response));
          setProductDetail(
            JSON.parse(this.responseText)?.data?.products?.slice(0, 10),
          );
          setLoading(false);
        }
      });

      xhr.open(
        'GET',
        `https://real-time-amazon-data.p.rapidapi.com/product-details?asin=${asin}&country=${region}`,
      );
      xhr.setRequestHeader(
        'x-rapidapi-key',
        '5e03767350msh99c49dea5537532p18783ejsnfe6cda87f482',
      );
      xhr.setRequestHeader(
        'x-rapidapi-host',
        'real-time-amazon-data.p.rapidapi.com',
      );

      xhr.send(data);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    bestSellers,
    loading,
    getBestSellers,
    searchProduct,
    getProductDetail,
    productDetail,
  };
};

export default useAmazon;
