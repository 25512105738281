import React from 'react';
import Box from '../../components/Box';
import { Container, Typography } from '@mui/material';
import useLocalContent, {
  type LanguageContent,
  type ContentInterface,
} from '../../utils/hooks/useLocalContent';
import COLORS from '../../utils/CONSTS/COLORS';
import Logo from '../../components/Logo';
import HeroSection from './componentes/HeroSection';
import SwitchLanguage from '../../components/SwitchLanguage';
import BrandsLogoSection from './componentes/BrandsLogoSection';
import { HOME_SCREEN_CONTENT } from '../../contents/HOME_SCREEN_CONTENT';
import { useNavigate } from 'react-router-dom';

const HomeScreen = () => {
  const content: LanguageContent = useLocalContent(HOME_SCREEN_CONTENT);

  const { header } = content;
  const navigate = useNavigate();
  return (
    <Box minHeight={'100vh'} minWidth={'100vw'} className="bg-gradient">
      <Container>
        <Box pt={20} row center spaceBetween>
          <Logo size={200} />
          <Box row center>
            <Box
              onClick={() => {
                navigate('login');
              }}
            >
              <Typography
                mr={2}
                color={COLORS.WHITE}
                variant="body1"
                fontWeight={'600'}
              >
                {header?.login}
              </Typography>
            </Box>
            <SwitchLanguage />
          </Box>
        </Box>
        <HeroSection content={content} />
      </Container>
      <Box
        backgroundColor={COLORS.WHITE}
        height={'50vh'}
        borderTopLeftRadius={30}
        borderTopRightRadius={30}
      >
        <BrandsLogoSection />
      </Box>
    </Box>
  );
};

export default HomeScreen;
